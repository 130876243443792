import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Config from '../../Config';
import Logo from '../../assets/images/logo/mag_ventures.svg';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import QRCode from "react-qr-code";
import { AmountToWords } from '../Dashboard/General/Functions';



const PackagePurchasePrint = () => {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reciept, setReciept] = useState('');

    const param = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "MAG Franchise";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            SecretID: param.id,
            Member_Type: MemberType,
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: Config.base_url + "Users/GetPackagebySecretID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log('response', response.data);
                if (response.data.status_code == 1) {
                    setReciept(response.data);
                    setLoading(false);
                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate('franchise/login')
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [isUpdated]);


    return (
        <>
            <ToastContainer />

            {loading ?
                <Spinner animation="border" role="status"></Spinner>
                :
                <div className="book">

                    <div className="page">
                        <div className="subpage">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <img src={Logo} style={{ width: "260px" }} />
                                </div>
                                <div className="col-6">
                                    <div className="footer-info justify-content-end">
                                        <p>connect@mag.holdings</p>
                                    </div>
                                    <div className="footer-info justify-content-end">
                                        <p>+92 318 333 4555</p>
                                    </div>

                                </div>
                            </div>
                            <div className="row align-items-center" style={{ marginTop: "38px" }}>
                                <div className="col-6">
                                    <div className="invoice-title">
                                        Purchase Invoice
                                    </div>
                                </div>
                            </div>


                            <div className="invoice-heading w-100 justify-content-center">
                                <label>Franchise ( <span> {reciept.PurchaserUser?.Group_Name} </span> )</label>
                            </div>

                            <div className="inv-box border-dashed d-flex align-items-center justify-content-center mt-4">
                                <ul className="inv-bordered-items">
                                    <li>
                                        <span>Full Name</span>
                                        <p>{reciept.Member?.Full_Name}</p>
                                    </li>
                                    <li>
                                        <span>Username</span>
                                        <p>{reciept.Member?.User_Name}</p>
                                    </li>
                                    <li>
                                        <span>Email Address</span>
                                        <p>{reciept.Member?.Email_Address}</p>
                                    </li>
                                </ul>
                            </div>
                          
                            <div className={`inv-box border-dashed d-flex align-items-center justify-content-center mt-4`}>
                                <ul className="inv-bordered-items">
                                    <li>
                                        <span>Package Name</span>
                                        <p>{reciept.PackagePurchase?.selectedPackage?.Name}</p>
                                    </li>
                                    <li>
                                        <span>Package Amount</span>
                                        <p>{reciept.PackagePurchase?.selectedPackage?.Amount} PKR</p>
                                    </li>
                                    <li className='w-100'>
                                        <span>Amount in Words</span>
                                        <p>{AmountToWords(reciept.PackagePurchase?.selectedPackage?.Amount)} only</p>
                                    </li>

                                    <li>
                                        <span>Status</span>
                                        <p>{reciept.PackagePurchase?.Status}</p>
                                    </li>
                                </ul>
                            </div>

                            <div className={`inv-box border-dashed d-flex align-items-center justify-content-center mt-4`}>
                                <ul className="inv-bordered-items">
                                    <li>
                                        <span>Purchased On</span>
                                        <p> {moment(reciept.PackagePurchase?.BuyingDate).format("L")}</p>
                                    </li>
                                    <li>
                                        <span>Delivered On</span>
                                        <p>{moment(reciept.PackagePurchase?.ClosingDate).format("L")}</p>
                                    </li>
                                    <li>
                                        <span>Product Name</span>
                                        <div className="invoice-heading border-0 p-0">
                                            <label className='text-black'>{reciept.PackagePurchase?.selectedPackage?.TagLine}</label>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="row mb-3 g-5" style={{ marginTop: '160px' }}>
                                <div className="col-5">
                                    <div className="invoice-sign">
                                        <div className="line"></div>
                                        <label>Customer Signature</label>
                                    </div>
                                </div>
                                <div className="col-5 offset-2">
                                    <div className="invoice-sign text-end">
                                        <div className="line"></div>
                                        <label>Authorised Signature</label>
                                    </div>
                                </div>
                            </div>



                            <div id="inv-footer" >
                                <div className="row align-items-center position-relative">
                                    <div className="col-10">
                                        <div className="note">
                                            <strong>Term and Condition:</strong>
                                            <ul>
                                                <li>This receiving invoice is issued for the purchase of products from MAG Ventures.</li>
                                                <li>The payment made by the customer ensures the delivery of the selected products by the company</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="qr-wrap">
                                            <QRCode
                                                size={146}
                                                style={{ height: "156", maxWidth: "100%", width: "100%" }}
                                                value={`https://www.mag.holdings/`}
                                                viewBox={`0 0 156 156`}
                                            />
                                        </div>
                                        <p className="text-center" style={{ fontSize: "10px", margin: "5px 0px 0px -5px" }}>www.mag.holdings</p>
                                    </div>
                                </div>
                                <div className="row g-0">
                                    <div className="col-2 offset-10">

                                    </div>
                                </div>
                            </div >
                            <div className="footer-info justify-content-center">
                                <span className="material-symbols-outlined">
                                    location_on
                                </span>
                                <p>{reciept.OfficeAddress}</p>
                                {/* <p>Rauf Tower First Floor, Main Gt Road, Jinnah Blvd west, DHA Phase 2, Islamabad.</p> */}
                            </div>
                        </div >
                    </div >
                </div >
            }
        </>
    )
}

export default PackagePurchasePrint